define('@mediapop/ember-humanize/helpers/humanize-compact-number', ['exports', 'humanize'], function (exports, _humanize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeCompactNumber = humanizeCompactNumber;
  function humanizeCompactNumber(params, namedArgs) {
    let [input] = params;

    let decimals = namedArgs.decimals === undefined ? 0 : namedArgs.decimals;

    return _humanize.default.compactInteger(input, decimals);
  }

  exports.default = Ember.Helper.helper(humanizeCompactNumber);
});
define("ember-bootstrap/components/bs-contextual-help/element", ["exports", "@ember/object", "@glimmer/component", "@ember/debug", "@ember/runloop", "ember-bootstrap/utils/decorators/arg", "@glimmer/tracking", "ember-ref-bucket"], function (_exports, _object, _component, _debug, _runloop, _arg, _tracking, _emberRefBucket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   Internal (abstract) component for contextual help markup. Should not be used directly.
  
   @class ContextualHelpElement
   @namespace Components
   @extends Glimmer.Component
   @private
   */
  let ContextualHelpElement = _exports.default = (_dec = (0, _emberRefBucket.trackedRef)('popperElement'), (_class = class ContextualHelpElement extends _component.default {
    constructor(...args) {
      super(...args);
      /**
       * @property placement
       * @type string
       * @default 'top'
       * @public
       */
      _initializerDefineProperty(this, "placement", _descriptor, this);
      _initializerDefineProperty(this, "actualPlacement", _descriptor2, this);
      /**
       * @property fade
       * @type boolean
       * @default true
       * @public
       */
      _initializerDefineProperty(this, "fade", _descriptor3, this);
      /**
       * @property showHelp
       * @type boolean
       * @default false
       * @public
       */
      _initializerDefineProperty(this, "showHelp", _descriptor4, this);
      /**
       * If true component will render in place, rather than be wormholed.
       *
       * @property renderInPlace
       * @type boolean
       * @default true
       * @public
       */
      /**
       * Which element to align to
       *
       * @property popperTarget
       * @type {string|HTMLElement}
       * @public
       */
      /**
       * @property autoPlacement
       * @type boolean
       * @default true
       * @public
       */
      /**
       * The DOM element of the viewport element.
       *
       * @property viewportElement
       * @type object
       * @public
       */
      /**
       * Take a padding into account for keeping the tooltip/popover within the bounds of the element given by `viewportElement`.
       *
       * @property viewportPadding
       * @type number
       * @default 0
       * @public
       */
      /**
       * @property arrowClass
       * @private
       */
      _defineProperty(this, "arrowClass", 'arrow');
      _defineProperty(this, "placementClassPrefix", '');
      _defineProperty(this, "offset", [0, 0]);
      _initializerDefineProperty(this, "popperElement", _descriptor5, this);
    }
    /**
     * popper.js modifier config
     *
     * @property popperModifiers
     * @type {object}
     * @private
     */
    get popperModifiers() {
      const context = this;

      // We need popeerElement, so we wait for this getter to recompute once it's available
      if (!this.popperElement) {
        return {};
      }
      return {
        arrow: {
          element: this.popperElement.querySelector(`.${this.arrowClass}`)
        },
        offset: {
          offset: this.offset.join(','),
          fn(data) {
            let tip = context.popperElement;
            (false && !(tip) && (0, _debug.assert)('Contextual help element needs existing popper element', tip)); // manually read margins because getBoundingClientRect includes difference
            let marginTop = parseInt(window.getComputedStyle(tip).marginTop, 10);
            let marginLeft = parseInt(window.getComputedStyle(tip).marginLeft, 10);

            // we must check for NaN for ie 8/9
            if (isNaN(marginTop) || marginTop > 0) {
              marginTop = 0;
            }
            if (isNaN(marginLeft) || marginLeft > 0) {
              marginLeft = 0;
            }
            data.offsets.popper.top += marginTop;
            data.offsets.popper.left += marginLeft;
            return window.Popper.Defaults.modifiers.offset.fn.apply(this, arguments);
          }
        },
        preventOverflow: {
          enabled: this.args.autoPlacement,
          boundariesElement: this.args.viewportElement,
          padding: this.args.viewportPadding
        },
        hide: {
          enabled: this.args.autoPlacement
        },
        flip: {
          enabled: this.args.autoPlacement
        }
      };
    }
    get actualPlacementClass() {
      let ending = this.actualPlacement;
      return this.placementClassPrefix + ending;
    }
    updatePlacement(popperDataObject) {
      if (this.actualPlacement === popperDataObject.placement) {
        return;
      }
      this.actualPlacement = popperDataObject.placement;
      (0, _runloop.scheduleOnce)('afterRender', popperDataObject.instance, popperDataObject.instance.scheduleUpdate);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "placement", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'top';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "actualPlacement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.placement;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fade", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showHelp", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "popperElement", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updatePlacement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePlacement"), _class.prototype)), _class));
});
define("ember-bootstrap/components/bs-navbar/nav", ["exports", "ember-bootstrap/components/bs-nav", "ember-bootstrap/utils/default-decorator"], function (_exports, _bsNav, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Component for the `.nav` element within a [Components.Navbar](Components.Navbar.html)
   * component. Have a look there for examples.
   *
   * Per [the bootstrap docs](http://getbootstrap.com/components/#navbar),
   * justified navbar nav links are not supported.
   *
   * @class NavbarNav
   * @namespace Components
   * @extends Components.Nav
   * @public
   */
  let NavbarNav = _exports.default = (_class = class NavbarNav extends _bsNav.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, '__ember-bootstrap_subclass', true);
      _initializerDefineProperty(this, "justified", _descriptor, this);
      _defineProperty(this, "additionalClass", 'navbar-nav');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "justified", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
});
define('@mediapop/ember-humanize/helpers/humanize-ordinal-number', ['exports', 'humanize'], function (exports, _humanize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeOrdinalNumber = humanizeOrdinalNumber;
  function humanizeOrdinalNumber(params /*, hash*/) {
    let [value] = params;
    return _humanize.default.ordinal(value);
  }

  exports.default = Ember.Helper.helper(humanizeOrdinalNumber);
});
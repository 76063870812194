define('@mediapop/ember-humanize/helpers/humanize-number', ['exports', 'humanize'], function (exports, _humanize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeNumber = humanizeNumber;
  function humanizeNumber(params, namedArgs) {
    let [number] = params;

    let precision = namedArgs.precision === undefined ? 0 : namedArgs.precision;
    let thousand = namedArgs.thousand === undefined ? ',' : namedArgs.thousand;
    let decimal = namedArgs.decimal === undefined ? '.' : namedArgs.decimal;

    return _humanize.default.formatNumber(number, precision, thousand, decimal);
  }

  exports.default = Ember.Helper.helper(humanizeNumber);
});
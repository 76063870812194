define("ember-bootstrap/components/bs-form", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object/computed", "@ember/object", "@ember/debug", "@ember/utils", "@ember/runloop", "rsvp", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _computed, _object, _debug, _utils, _runloop, _rsvp, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-invalid-interactive --}}
  <form
    novalidate={{this.hasValidator}}
    class={{this.layoutClass}}
    ...attributes
    {{on "keypress" this.handleKeyPress}}
    {{on "submit" this.handleSubmit}}
  >
    {{yield
      (hash
        element=(component (ensure-safe-component (bs-default @elementComponent (component "bs-form/element")))
          model=this.model
          formLayout=this.formLayout
          horizontalLabelGridClass=this.horizontalLabelGridClass
          showAllValidations=this.showAllValidations
          _disabled=this.disabled
          _readonly=this.readonly
          onChange=this.elementChanged
          _onChange=this.resetSubmissionState
        )
        group=(component (ensure-safe-component (bs-default @groupComponent (component "bs-form/group")) formLayout=this.formLayout))
        isSubmitting=this.isSubmitting
        isSubmitted=this.isSubmitted
        isRejected=this.isRejected
        resetSubmissionState=this.resetSubmissionState
        submit=this.doSubmit
        submitButton=(component (ensure-safe-component (bs-default @submitButtonComponent (component "bs-button")))
          buttonType="submit"
          type="primary"
          state=this.submitButtonState
          _disabled=this.isSubmitting
        )
      )
    }}
  </form>
  
  */
  {
    "id": "2cCa+I3s",
    "block": "[[[11,\"form\"],[16,\"novalidate\",[30,0,[\"hasValidator\"]]],[16,0,[30,0,[\"layoutClass\"]]],[17,1],[4,[38,0],[\"keypress\",[30,0,[\"handleKeyPress\"]]],null],[4,[38,0],[\"submit\",[30,0,[\"handleSubmit\"]]],null],[12],[1,\"\\n  \"],[18,5,[[28,[37,2],null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\",\"submit\",\"submitButton\"],[[50,[28,[37,4],[[28,[37,5],[[30,2],[50,\"bs-form/element\",0,null,null]],null]],null],0,null,[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"_disabled\",\"_readonly\",\"onChange\",\"_onChange\"],[[30,0,[\"model\"]],[30,0,[\"formLayout\"]],[30,0,[\"horizontalLabelGridClass\"]],[30,0,[\"showAllValidations\"]],[30,0,[\"disabled\"]],[30,0,[\"readonly\"]],[30,0,[\"elementChanged\"]],[30,0,[\"resetSubmissionState\"]]]]],[50,[28,[37,4],[[28,[37,5],[[30,3],[50,\"bs-form/group\",0,null,null]],null]],[[\"formLayout\"],[[30,0,[\"formLayout\"]]]]],0,null,null],[30,0,[\"isSubmitting\"]],[30,0,[\"isSubmitted\"]],[30,0,[\"isRejected\"]],[30,0,[\"resetSubmissionState\"]],[30,0,[\"doSubmit\"]],[50,[28,[37,4],[[28,[37,5],[[30,4],[50,\"bs-button\",0,null,null]],null]],null],0,null,[[\"buttonType\",\"type\",\"state\",\"_disabled\"],[\"submit\",\"primary\",[30,0,[\"submitButtonState\"]],[30,0,[\"isSubmitting\"]]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@elementComponent\",\"@groupComponent\",\"@submitButtonComponent\",\"&default\"],false,[\"on\",\"yield\",\"hash\",\"component\",\"ensure-safe-component\",\"bs-default\"]]",
    "moduleName": "ember-bootstrap/components/bs-form.hbs",
    "isStrictMode": false
  });
  /**
    Render a form with the appropriate Bootstrap layout class (see `formLayout`).
    Allows setting a `model` that nested `Components.FormElement`s can access, and that can provide form validation (see below)
  
    You can use whatever markup you like within the form. The following shows Bootstrap 3 usage for the internal markup.
  
    ```handlebars
    <BsForm @onSubmit={{action "submit"}} as |form|>
      <form.group>
        <label class="control-label">First name</label>
        <input value={{this.firstname}} class="form-control" oninput={{action (mut this.firstname) value="target.value"}} type="text">
      </form.group>
    </BsForm>
    ```
  
    However to benefit from features such as automatic form markup, validations and validation markup, use `Components.FormElement`
    as nested components. See below for an example.
  
    ### Submitting the form
  
    The form yields a `submitButton` component, which is a preconfigured `<BsButton>` with `@type="primary"` and `@buttonType="submit"`.
    The button is disabled while a form submission is pending. Additionally the button state is bound to the form submission state.
  
    ```hbs
    <BsForm as |form|>
      <form.submitButton>Submit</form.submitButton>
    </BsForm>
    ```
  
    When the form is submitted (e.g. by clicking the submit button), the event will be intercepted and the `onSubmit` action
    will be sent to the controller or parent component.
    In case the form supports validation (see "Form validation" below), the `onBefore` action is called (which allows you to
    do e.g. model data normalization), then the available validation rules are evaluated, and if those fail, the `onInvalid`
    action is sent instead of `onSubmit`.
  
    ### Use with Components.FormElement
  
    When using `Components.FormElement`s with their `property` set to property names of the form's validation enabled
    `model`, you gain some additional powerful features:
    * the appropriate Bootstrap markup for the given `formLayout` and the form element's `controlType` is automatically generated
    * markup for validation states and error messages is generated based on the model's validation (if available), when submitting the form
    with an invalid validation, or when focusing out of invalid inputs
  
    ```handlebars
    <BsForm @formLayout="horizontal" @model={{this}} @onSubmit={{action "submit"}} as |form|>
      <form.element @controlType="email" @label="Email" @placeholder="Email" @property="email" />
      <form.element @controlType="password" @label="Password" @placeholder="Password" @property="password" />
      <form.element @controlType="checkbox" @label="Remember me" @property="rememberMe" />
      <form.submitButton>Submit</form.submitButton>
    </BsForm>
    ```
  
    See the [Components.FormElement](Components.FormElement.html) API docs for further information.
  
    ### Form validation
  
    All version of ember-bootstrap beginning from 0.7.0 do not come with built-in support for validation engines anymore.
    Instead support is added usually by additional Ember addons, for example:
  
    * [ember-bootstrap-validations](https://github.com/kaliber5/ember-bootstrap-validations): adds support for [ember-validations](https://github.com/DockYard/ember-validations)
    * [ember-bootstrap-cp-validations](https://github.com/offirgolan/ember-bootstrap-cp-validations): adds support for [ember-cp-validations](https://github.com/offirgolan/ember-cp-validations)
    * [ember-bootstrap-changeset-validations](https://github.com/kaliber5/ember-bootstrap-changeset-validations): adds support for [ember-changeset](https://github.com/poteto/ember-changeset)
  
    To add your own validation support, you have to:
  
    * extend this component, setting `hasValidator` to true if validations are available (by means of a computed property for example), and implementing the `validate` method
    * extend the [Components.FormElement](Components.FormElement.html) component and implement the `setupValidations` hook or simply override the `errors` property to add the validation error messages to be displayed
  
    When validation fails, the appropriate Bootstrap markup is added automatically, i.e. the error classes are applied and
    the validation messages are shown for each form element. In case the validation library supports it, also warning messages
    are shown. See the [Components.FormElement](Components.FormElement.html) documentation for further details.
  
    See the above mentioned addons for examples.
  
    The `novalidate` HTML attribute is set by default for forms that have validation.
  
    ### Submission state
  
    A `isSubmitting` property is yielded, which is `true` after submit has been triggered and before the Promise returned
    by `onSubmit` is fulfilled. It could be used to show a loading spinner instead of the form while it's submitting for example:
  
    ```hbs
    <BsForm @onSubmit={{action "save"}} as |form|>
      {{#if form.isSubmitting}}
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Logging in...
      {{else}}
        <form.element @property="email" @label="email" />
        <form.element @property="password" @label="password" @controlType="password" />
        <form.submitButton>Login</form.submitButton>
      {{/if}}
    </BsForm>
    ```
  
    Additionaly `isSubmitted` and `isRejected` properties are yielded. `isSubmitted` is `true` if last submission was successful.
    `isRejected` is `true` if last submission was rejected due to validation errors or by an action bound to `onSubmit` event, returning a rejected promise.
    It could be used for visual feedback about last submission:
  
    ```hbs
    <BsForm @onSubmit={{action 'save}} as |form|>
      <form.submitButton @type={{if form.isRejected "danger" "primary"}}>
        Save
      </form.submitButton>
    </BsForm>
    ```
  
    The submission state is reset as soon as any value of a form element changes. Additionally it can be reset programatically by
    calling the yielded `resetSubmissionState` function.
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Form
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Form = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('formLayout'), _dec3 = (0, _computed.gt)('pendingSubmissions', 0), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class Form extends _component.default {
    constructor(...args) {
      super(...args);
      /**
       * Set a model that this form should represent. This serves several purposes:
       *
       * * child `Components.FormElement`s can access and bind to this model by their `property`
       * * when the model supports validation by using the [ember-validations](https://github.com/dockyard/ember-validations) mixin,
       * child `Components.FormElement`s will look at the validation information of their `property` and render their form group accordingly.
       * Moreover the form's `submit` event handler will validate the model and deny submitting if the model is not validated successfully.
       *
       * @property model
       * @type object
       * @public
       */
      _initializerDefineProperty(this, "model", _descriptor, this);
      /**
       * Set the layout of the form to either "vertical", "horizontal" or "inline". See http://getbootstrap.com/css/#forms-inline and http://getbootstrap.com/css/#forms-horizontal
       *
       * @property formLayout
       * @type string
       * @public
       */
      _initializerDefineProperty(this, "formLayout", _descriptor2, this);
      /**
       * Check if validating the model is supported. This needs to be implemented by another addon.
       *
       * @property hasValidator
       * @type boolean
       * @readonly
       * @protected
       */
      /**
       * The Bootstrap grid class for form labels. This is used by the `Components.FormElement` class as a default for the
       * whole form.
       *
       * @property horizontalLabelGridClass
       * @type string
       * @default 'col-md-4'
       * @public
       */
      _initializerDefineProperty(this, "horizontalLabelGridClass", _descriptor3, this);
      /**
       * @property elementComponent
       * @type {String}
       * @private
       */
      /**
       * @property groupComponent
       * @type {String}
       * @private
       */
      /**
       * @property submitButtonComponent
       * @type {String}
       * @private
       */
      /**
       * `isSubmitting` is `true` after `submit` event has been triggered and until Promise returned by `onSubmit` is
       * fulfilled. If `validate` returns a Promise that one is also taken into consideration.
       *
       * If multiple concurrent submit events are fired, it stays `true` until all submit events have been fulfilled.
       *
       * @property isSubmitting
       * @type {Boolean}
       * @readonly
       * @private
       */
      _initializerDefineProperty(this, "isSubmitting", _descriptor4, this);
      /**
       * `isSubmitted` is `true` if last submission was successful.
       * A change to any form element resets it's value to `false`.
       *
       * If not using `Components.FormElement`, `resetSubmissionState` action must be triggered on each change to reset
       * form's submission state.
       *
       * @property isSubmitted
       * @type {Boolean}
       * @private
       */
      _initializerDefineProperty(this, "isSubmitted", _descriptor5, this);
      /**
       * `isRejected` is `true` if last submission was rejected.
       * A submission is considered as rejected if form is invalid as well as if `onSubmit` rejects.
       * A change to any form element resets it's value to `false`.
       *
       * If not using `Components.FormElement`, `resetSubmissionState` action must be triggered on each change to reset
       * form's submission state.
       *
       * @property isRejected
       * @type {Boolean}
       * @private
       */
      _initializerDefineProperty(this, "isRejected", _descriptor6, this);
      /**
       * Count of pending submissions.
       *
       * @property pendingSubmissions
       * @type {Integer}
       * @private
       */
      _initializerDefineProperty(this, "pendingSubmissions", _descriptor7, this);
      /**
       * If set to true pressing enter will submit the form, even if no submit button is present
       *
       * @property submitOnEnter
       * @type boolean
       * @default false
       * @public
       */
      _initializerDefineProperty(this, "submitOnEnter", _descriptor8, this);
      /**
       * Controls if `onSubmit` action is fired concurrently. If `true` submitting form multiple
       * times will not trigger `onSubmit` action if a Promise returned by previous submission is
       * not settled yet.
       *
       * Droping a submission also prevents rerunning validation and `onBefore` hook.
       *
       * @property preventConcurrency
       * @type Boolean
       * @default true
       * @public
       */
      _initializerDefineProperty(this, "preventConcurrency", _descriptor9, this);
      /**
       * If true, after successful validation and upon submitting the form, all current element validations will be hidden.
       * If the form remains visible, the user would have to focus out of elements of submit the form again for the
       * validations to show up again, as if a fresh new form component had been rendered.
       *
       * @property hideValidationsOnSubmit
       * @type {Boolean}
       * @default false
       * @public
       */
      _initializerDefineProperty(this, "hideValidationsOnSubmit", _descriptor10, this);
      /**
       * If set to true the `readonly` property of all yielded form elements will be set, making their form controls read-only.
       *
       * @property readonly
       * @type boolean
       * @default false
       * @public
       */
      _initializerDefineProperty(this, "readonly", _descriptor11, this);
      /**
       * If set to true the `disabled` property of all yielded form elements will be set, making their form controls disabled.
       *
       * @property disabled
       * @type boolean
       * @default false
       * @public
       */
      _initializerDefineProperty(this, "disabled", _descriptor12, this);
      // eslint-disable-line no-unused-vars
      /**
       * @property showAllValidations
       * @type boolean
       * @default undefined
       * @private
       */
      _initializerDefineProperty(this, "showAllValidations", _descriptor13, this);
    }
    /**
     * Bootstrap form class name (computed)
     *
     * @property layoutClass
     * @type string
     * @readonly
     * @protected
     *
     */
    get layoutClass() {
      let layout = this.formLayout;
      {
        return layout === 'inline' ? 'form-inline' : null;
      }
    }
    /**
     * State of the form expressed as state values expected by `<BsButton>`.
     *
     * @property submitButtonState
     * @type {String}
     * @private
     */
    get submitButtonState() {
      if (this.isSubmitting) {
        return 'pending';
      }
      if (this.isSubmitted) {
        return 'fulfilled';
      }
      if (this.isRejected) {
        return 'rejected';
      }
      return 'default';
    }
    /**
     * Validate hook which will return a promise that will either resolve if the model is valid
     * or reject if it's not. This should be overridden to add validation support.
     *
     * @method validate
     * @param {Object} model
     * @return {Promise}
     * @public
     */
    validate(model) {}
    /**
     * Action is called before the form is validated (if possible) and submitted.
     *
     * @event onBefore
     * @param { Object } model  The form's `model`
     * @public
     */

    /**
     * Action is called when submit has been triggered and the model has passed all validations (if present).
     *
     * @event onSubmit
     * @param { Object } model  The form's `model`
     * @param { Object } result The returned result from the validate method, if validation is available
     * @public
     */

    /**
     * Action is called when validation of the model has failed.
     *
     * @event onInvalid
     * @param { Object } model  The form's `model`
     * @param { Object } error
     * @public
     */

    /**
     * Submit handler that will send the default action ("action") to the controller when submitting the form.
     *
     * If there is a supplied `model` that supports validation (`hasValidator`) the model will be validated before, and
     * only if validation is successful the default action will be sent. Otherwise an "invalid" action will be sent, and
     * all the `showValidation` property of all child `Components.FormElement`s will be set to true, so error state and
     * messages will be shown automatically.
     *
     * @method submit
     * @private
     */
    submitHandler(e, throwValidationErrors = true) {
      if (e) {
        e.preventDefault();
      }
      if (this.preventConcurrency && this.isSubmitting) {
        return _rsvp.default.resolve();
      }
      let model = this.model;
      this.incrementProperty('pendingSubmissions');
      if (typeof this.onBefore === 'function') {
        this.onBefore(model);
      }
      return _rsvp.default.resolve().then(() => {
        return this.hasValidator ? this.validate(model) : null;
      }).then(record => {
        if (this.hideValidationsOnSubmit === true) {
          this.set('showAllValidations', false);
        }
        return _rsvp.default.resolve().then(() => {
          if (typeof this.onSubmit === 'function') {
            return this.onSubmit(model, record);
          }
        }).then(() => {
          if (this.isDestroyed) {
            return;
          }
          this.set('isSubmitted', true);
        }).catch(error => {
          if (this.isDestroyed) {
            return;
          }
          this.set('isRejected', true);
          throw error;
        }).finally(() => {
          if (this.isDestroyed) {
            return;
          }
          this.decrementProperty('pendingSubmissions');

          // reset forced hiding of validations
          if (this.showAllValidations === false) {
            (0, _runloop.schedule)('afterRender', () => this.set('showAllValidations', undefined));
          }
        });
      }, error => {
        return _rsvp.default.resolve().then(() => {
          if (typeof this.onInvalid === 'function') {
            return this.onInvalid(model, error);
          }
        }).finally(() => {
          if (this.isDestroyed) {
            return;
          }
          this.setProperties({
            showAllValidations: true,
            isRejected: true,
            pendingSubmissions: this.pendingSubmissions - 1
          });
          if (throwValidationErrors) {
            throw error;
          }
        });
      });
    }
    handleSubmit(event) {
      this.submitHandler(event, false);
    }
    handleKeyPress(event) {
      let code = event.keyCode || event.which;
      if (code === 13 && this.submitOnEnter) {
        let submitEvent = document.createEvent('Event');
        submitEvent.initEvent('submit', true, true);
        event.target.dispatchEvent(submitEvent);
      }
    }
    init() {
      super.init(...arguments);
      let formLayout = this.formLayout;
      (false && !(['vertical', 'horizontal', 'inline'].indexOf(formLayout) >= 0) && (0, _debug.assert)(`Invalid formLayout property given: ${formLayout}`, ['vertical', 'horizontal', 'inline'].indexOf(formLayout) >= 0));
      if (false /* DEBUG */) {
        (false && (0, _debug.warn)(`Argument novalidate of <BsForm> component has been removed. ` + `Its only purpose was setting the HTML attribute novalidate of the <form> element. ` + `You should use angle bracket component invocation syntax instead:\n` + `Before:n` + `  {{bs-form novalidate=true}}\n` + `  <BsForm @novalidate={{true}} />\n` + `After:\n` + `  <BsForm novalidate>\n` + `A codemod is available to help with the required migration. See https://github.com/kaliber5/ember-bootstrap-codemods/blob/master/transforms/deprecated-attribute-arguments/README.md`,
        // eslint-disable-next-line ember/no-attrs-in-components
        !Object.keys(this.attrs).includes('novalidate'), {
          id: `ember-bootstrap.removed-argument.form#novalidate`
        }));
      }
    }
    elementChanged(value, model, property) {
      (false && !((0, _utils.isPresent)(model) && (0, _utils.isPresent)(property)) && (0, _debug.assert)("You cannot use the form element's default onChange action for form elements if not using a model or setting the value directly on a form element. You must add your own onChange action to the form element in this case!", (0, _utils.isPresent)(model) && (0, _utils.isPresent)(property)));
      if (typeof model.set === 'function') {
        model.set(property, value);
      } else {
        (0, _object.set)(model, property, value);
      }
    }
    resetSubmissionState() {
      this.set('isSubmitted', false);
      this.set('isRejected', false);
    }
    doSubmit() {
      return this.submitHandler();
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "layoutClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "layoutClass"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "model", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "formLayout", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'vertical';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "horizontalLabelGridClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'col-md-4';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isSubmitting", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isSubmitted", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isRejected", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "pendingSubmissions", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "submitOnEnter", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "preventConcurrency", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "hideValidationsOnSubmit", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "readonly", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "showAllValidations", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "handleSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSubmit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyPress", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyPress"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "elementChanged", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "elementChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetSubmissionState", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetSubmissionState"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doSubmit"), _class2.prototype)), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Form);
});
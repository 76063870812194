define("ember-bootstrap/components/bs-popover/element", ["exports", "@ember/component", "@ember/template-factory", "ember-bootstrap/components/bs-contextual-help/element"], function (_exports, _component, _templateFactory, _element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmberPopper
    @ariaRole="tooltip"
    @placement={{this.placement}}
    @renderInPlace={{@renderInPlace}}
    @popperTarget={{@popperTarget}}
    @modifiers={{this.popperModifiers}}
    @popperContainer={{@destinationElement}}
    @onCreate={{this.updatePlacement}}
    @onUpdate={{this.updatePlacement}}
    @class="popover {{@class}} {{if this.fade "fade"}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) this.actualPlacementClass}} {{if (macroCondition (macroGetOwnConfig "isBS3")) this.actualPlacement}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if this.showHelp "show")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) (if this.showHelp "in")}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "ember-bootstrap-popover"}}"
  
    {{create-ref "popperElement"}}
    ...attributes
  >
    <div class={{this.arrowClass}}></div>
    {{#if @title}}
      <h3 class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "popover-header"}}{{if (macroCondition (macroGetOwnConfig "isBS3")) "popover-title"}}">{{@title}}</h3>
    {{/if}}
    <div class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "popover-body"}}{{if (macroCondition (macroGetOwnConfig "isBS3")) "popover-content"}}">{{yield}}</div>
  </EmberPopper>
  
  */
  {
    "id": "F03QTJW/",
    "block": "[[[8,[39,0],[[17,1],[4,[38,2],[\"popperElement\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[30,0]]]]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@class\"],[\"tooltip\",[30,0,[\"placement\"]],[30,2],[30,3],[30,0,[\"popperModifiers\"]],[30,4],[30,0,[\"updatePlacement\"]],[30,0,[\"updatePlacement\"]],[29,[\"popover \",[30,5],\" \",[52,[30,0,[\"fade\"]],\"fade\"],\" \",[30,0,[\"actualPlacementClass\"]],\" \",[27],\" \",[52,[30,0,[\"showHelp\"]],\"show\"],\" \",[27],\" \",[27]]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"arrowClass\"]]],[12],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"    \"],[10,\"h3\"],[15,0,[29,[\"popover-header\",[27]]]],[12],[1,[30,6]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[15,0,[29,[\"popover-body\",[27]]]],[12],[18,7,null],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@renderInPlace\",\"@popperTarget\",\"@destinationElement\",\"@class\",\"@title\",\"&default\"],false,[\"ember-popper\",\"if\",\"create-ref\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-popover/element.hbs",
    "isStrictMode": false
  });
  /**
   Internal component for popover's markup. Should not be used directly.
  
   @class PopoverElement
   @namespace Components
   @extends Components.ContextualHelpElement
   @private
   */
  class PopoverElement extends _element.default {
    constructor(...args) {
      super(...args);
      /**
       * @property title
       * @type string
       * @public
       */
      _defineProperty(this, "arrowClass", 'arrow');
      _defineProperty(this, "placementClassPrefix", 'bs-popover-');
      _defineProperty(this, "offset", [0, 0]);
    }
  }
  _exports.default = PopoverElement;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PopoverElement);
});
define("ember-cli-flash/components/flash-message", ["exports", "@ember/component", "@ember/string", "@ember/template", "@ember/utils", "@ember/runloop", "@ember/object", "@ember/object/computed", "ember-cli-flash/templates/components/flash-message"], function (_exports, _component, _string, _template, _utils, _runloop, _object, _computed, _flashMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const {
    next,
    cancel
  } = _runloop.run;
  let FlashMessage = _exports.default = (_dec = (0, _computed.readOnly)('flash.showProgress'), _dec2 = (0, _computed.not)('exiting'), _dec3 = (0, _computed.and)('showProgress', 'notExiting'), _dec4 = (0, _computed.readOnly)('flash.exiting'), _dec5 = (0, _object.computed)('messageStyle'), _dec6 = (0, _object.computed)('flash.type', 'messageStylePrefix', '_defaultMessageStylePrefix'), _dec7 = (0, _object.computed)('flash.type'), _dec8 = (0, _object.computed)('flash.{showProgress,timeout}'), (_class = class FlashMessage extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "layout", _flashMessage.default);
      _defineProperty(this, "active", false);
      _defineProperty(this, "messageStyle", 'bootstrap');
      _initializerDefineProperty(this, "showProgress", _descriptor, this);
      _initializerDefineProperty(this, "notExiting", _descriptor2, this);
      _initializerDefineProperty(this, "showProgressBar", _descriptor3, this);
      _initializerDefineProperty(this, "exiting", _descriptor4, this);
    }
    get _defaultMessageStylePrefix() {
      const isFoundation = this.messageStyle === 'foundation';
      return isFoundation ? 'alert-box ' : 'alert alert-';
    }
    get alertType() {
      const flashType = this.flash.type || '';
      const prefix = this.messageStylePrefix || this._defaultMessageStylePrefix;
      return `${prefix}${flashType}`;
    }
    get flashType() {
      return (0, _string.classify)(this.flash.type || '');
    }
    get progressDuration() {
      if (!this.flash?.showProgress) {
        return false;
      }
      const duration = this.flash?.timeout || 0;
      return (0, _template.htmlSafe)(`transition-duration: ${duration}ms`);
    }
    _mouseEnter() {
      if ((0, _utils.isPresent)(this.flash)) {
        this.flash.preventExit();
      }
    }
    _mouseLeave() {
      if ((0, _utils.isPresent)(this.flash) && !this.flash.exiting) {
        this.flash.allowExit();
      }
    }
    _destroyFlashMessage() {
      if (this.flash) {
        this.flash.destroyMessage();
      }
    }
    onClick() {
      const destroyOnClick = this.flash?.destroyOnClick ?? true;
      if (destroyOnClick) {
        this._destroyFlashMessage();
      }
    }
    onClose() {
      this._destroyFlashMessage();
    }
    onDidInsert(element) {
      const pendingSet = next(this, () => {
        (0, _object.set)(this, 'active', true);
      });
      (0, _object.set)(this, 'pendingSet', pendingSet);
      (0, _object.set)(this, '_mouseEnterHandler', this._mouseEnter);
      (0, _object.set)(this, '_mouseLeaveHandler', this._mouseLeave);
      element.addEventListener('mouseenter', this._mouseEnterHandler);
      element.addEventListener('mouseleave', this._mouseLeaveHandler);
    }
    onWillDestroy(element) {
      element.removeEventListener('mouseenter', this._mouseEnterHandler);
      element.removeEventListener('mouseleave', this._mouseLeaveHandler);
      cancel(this.pendingSet);
      this._destroyFlashMessage();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showProgress", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notExiting", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showProgressBar", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "exiting", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_defaultMessageStylePrefix", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_defaultMessageStylePrefix"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alertType", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "alertType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "flashType", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "flashType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "progressDuration", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "progressDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onWillDestroy"), _class.prototype)), _class));
});
define("ember-resource-tasks/-private/memoized-task", ["exports", "ember-could-get-used-to-this", "@ember/object", "@ember/debug", "@ember/application", "ember-concurrency-ts", "ember-concurrency-decorators", "ember-resource-tasks/-private/utils"], function (_exports, _emberCouldGetUsedToThis, _object, _debug, _application, _emberConcurrencyTs, _emberConcurrencyDecorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TASK_PROPERTY = _exports.TASK_INSTANCE_FROM_CACHE = _exports.MemoizedTask = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const CACHE = 'service:ember-resource-tasks/-private/do-not-use/arg-cache';
  const TASK_PROPERTY = _exports.TASK_PROPERTY = Symbol('TASK');
  const TASK_INSTANCE_FROM_CACHE = _exports.TASK_INSTANCE_FROM_CACHE = Symbol('TASK_FROM_CACHE');

  /**
   * Whenever any args change *value*, the task will re run
   * All args must be stringish
   */
  let MemoizedTask = _exports.MemoizedTask = (_class = class MemoizedTask extends _emberCouldGetUsedToThis.Resource {
    /**
     * @public
     *
     * This is the return value of a resource
     */
    get value() {
      let task = this.cacheBucket.get(this.cacheKey);
      (false && !(task) && (0, _debug.assert)(`A task failed to start`, task)); // entangle with the task's properties when it resolves
      (0, _utils.consumeTag)(task, 'value');
      (0, _utils.consumeTag)(task, 'isFinished');
      (0, _utils.consumeTag)(task, 'error');
      let result = {
        ...(0, _utils.extractTaskData)(task),
        retry: this._perform
      };
      if (false /* DEBUG */) {
        result[TASK_INSTANCE_FROM_CACHE] = task;
        result[TASK_PROPERTY] = task;
      }
      return result;
    }
    get cacheKey() {
      return (0, _utils.toCacheKey)(...this.args.named.args);
    }
    get needsUpdate() {
      if (!this.cacheBucket.has(this.cacheKey)) {
        return true;
      }
      return this.cacheBucket.get(this.cacheKey)?.isCanceled;
    }
    get _task() {
      return (0, _emberConcurrencyTs.taskFor)(this.__task);
    }
    *__task() {
      let {
        fn,
        args
      } = this.args.named;

      // Because async functions can set tracked data during rendering, (before an await is hit in execution)
      // we are presented with this assertion:
      //
      // Error: Assertion Failed: You attempted to update `someProperty` on `<SomeObject:ember1159>`,
      // but it had already been used previously in the same computation.
      // Attempting to update a value after using it in a computation can cause logical errors,
      // infinite revalidation bugs, and performance issues, and is not supported.
      yield Promise.resolve();
      let result = yield fn(...args);
      return result;
    }
    _perform() {
      let task = this._task.perform();
      this.cacheBucket.set(this.cacheKey, task);
    }

    /**
     * Resource lifecycle methods
     */
    setup() {
      this.cacheBucket = (0, _application.getOwner)(this).lookup(CACHE).getEnsuringBucket(this.args.named.cacheKey);
      if (this.needsUpdate) {
        this._perform();
      }
    }
    update() {
      if (this.needsUpdate) {
        this._perform();
      }
    }
    teardown() {
      this._task.cancelAll();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "__task", [_emberConcurrencyDecorators.task, _utils.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "__task"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_perform", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_perform"), _class.prototype)), _class);
});
define("ember-bootstrap/components/bs-nav/item", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember-decorators/object", "@ember/object/computed", "@ember/object", "@ember/runloop", "ember-bootstrap/components/bs-link-to", "ember-bootstrap/mixins/component-parent", "ember-bootstrap/utils/cp/overrideable", "@ember/debug", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _object2, _runloop, _bsLinkTo, _componentParent, _overrideable, _debug, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-invalid-interactive --}}
  <li class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "nav-item"}} {{if this.disabled "disabled"}} {{if this.active "active"}}" ...attributes {{on "click" this.handleClick}}>
    {{yield}}
  </li>
  */
  {
    "id": "vqgPT3J3",
    "block": "[[[11,\"li\"],[16,0,[29,[\"nav-item\",\" \",[52,[30,0,[\"disabled\"]],\"disabled\"],\" \",[52,[30,0,[\"active\"]],\"active\"]]]],[17,1],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-nav/item.hbs",
    "isStrictMode": false
  });
  /**
  
   Component for each item within a [Components.Nav](Components.Nav.html) component. Have a look there for examples.
  
   @class NavItem
   @namespace Components
   @extends Ember.Component
   @uses Mixins.ComponentParent
   @public
   */
  let NavItem = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _overrideable.default)('_disabled', function () {
    return this._disabled;
  }), _dec3 = (0, _overrideable.default)('_active', function () {
    return this._active;
  }), _dec4 = (0, _computed.filter)('children', function (view) {
    return view instanceof _bsLinkTo.default;
  }), _dec5 = (0, _computed.filterBy)('childLinks', 'active'), _dec6 = (0, _computed.gt)('activeChildLinks.length', 0), _dec7 = (0, _computed.filterBy)('childLinks', 'disabled'), _dec8 = (0, _computed.gt)('disabledChildLinks.length', 0), _dec9 = (0, _object.observes)('activeChildLinks.[]'), _dec10 = (0, _object.observes)('disabledChildLinks.[]'), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class NavItem extends _component.default.extend(_componentParent.default) {
    constructor(...args) {
      super(...args);
      /**
       * Render the nav item as disabled (see [Bootstrap docs](http://getbootstrap.com/components/#nav-disabled-links)).
       * By default it will look at any nested `link-to` components and make itself disabled if there is a disabled link.
       * See the [link-to API](http://emberjs.com/api/classes/Ember.Templates.helpers.html#toc_disabling-the-code-link-to-code-component)
       *
       * @property disabled
       * @type boolean
       * @public
       */
      _initializerDefineProperty(this, "disabled", _descriptor, this);
      _defineProperty(this, "_disabled", false);
      /**
       * Render the nav item as active.
       * By default it will look at any nested `link-to` components and make itself active if there is an active link
       * (i.e. the link points to the current route).
       * See the [link-to API](http://emberjs.com/api/classes/Ember.Templates.helpers.html#toc_handling-current-route)
       *
       * @property active
       * @type boolean
       * @public
       */
      _initializerDefineProperty(this, "active", _descriptor2, this);
      _defineProperty(this, "_active", false);
      /**
       * Collection of all `Ember.LinkComponent`s that are children
       *
       * @property childLinks
       * @private
       */
      _initializerDefineProperty(this, "childLinks", _descriptor3, this);
      _initializerDefineProperty(this, "activeChildLinks", _descriptor4, this);
      _initializerDefineProperty(this, "hasActiveChildLinks", _descriptor5, this);
      _initializerDefineProperty(this, "disabledChildLinks", _descriptor6, this);
      _initializerDefineProperty(this, "hasDisabledChildLinks", _descriptor7, this);
    }
    /**
     * Called when clicking the nav item
     *
     * @event onClick
     * @public
     */
    onClick() {}
    handleClick() {
      this.onClick();
    }
    init() {
      super.init(...arguments);
      let {
        model,
        models
      } = this;
      (false && !(!model || !models) && (0, _debug.assert)('You cannot pass both `@model` and `@models` to a nav item component!', !model || !models));
      this.activeChildLinks;
      this.disabledChildLinks;
    }
    _observeActive() {
      (0, _runloop.scheduleOnce)('afterRender', this, this._updateActive);
    }
    _updateActive() {
      this.set('_active', this.hasActiveChildLinks);
    }
    _observeDisabled() {
      (0, _runloop.scheduleOnce)('afterRender', this, this._updateDisabled);
    }
    _updateDisabled() {
      this.set('_disabled', this.hasDisabledChildLinks);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "active", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "childLinks", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "activeChildLinks", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "hasActiveChildLinks", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "disabledChildLinks", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "hasDisabledChildLinks", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_observeActive", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "_observeActive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_observeDisabled", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "_observeDisabled"), _class2.prototype)), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NavItem);
});
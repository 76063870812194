define("ember-could-get-used-to-this/-private/functions", ["@ember/helper", "@ember/debug"], function (_helper, _debug) {
  "use strict";

  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class FunctionalHelperManager {
    constructor() {
      _defineProperty(this, "capabilities", (0, _helper.capabilities)('3.23', {
        hasValue: true
      }));
    }
    createHelper(fn, args) {
      return {
        fn,
        args
      };
    }
    getValue({
      fn,
      args
    }) {
      (false && !(Object.keys(args.named).length === 0) && (0, _debug.assert)(`Functional helpers cannot receive hash parameters. \`${this.getDebugName(fn)}\` received ${Object.keys(args.named)}`, Object.keys(args.named).length === 0));
      return fn(...args.positional);
    }
    getDebugName(fn) {
      return fn.name || '(anonymous function)';
    }
  }
  const FUNCTIONAL_HELPER_MANAGER = new FunctionalHelperManager();
  (0, _helper.setHelperManager)(() => FUNCTIONAL_HELPER_MANAGER, Function.prototype);
});
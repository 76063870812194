define("ember-bootstrap-changeset-validations/components/bs-form/element", ["exports", "ember-bootstrap/components/bs-form/element", "@ember/object", "@ember/utils"], function (_exports, _element, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let BsFormElementWithChangesetValidationsSupport = _exports.default = (_class = class BsFormElementWithChangesetValidationsSupport extends _element.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, '__ember-bootstrap_subclass', true);
    }
    get errors() {
      let {
        model,
        property
      } = this.args;

      // must use `get` method to support nested properties
      let errors = (0, _object.get)(model, `error.${property}.validation`);

      // no messages
      if ((0, _utils.isNone)(errors)) {
        return [];
      }

      // a single messages
      if ((0, _utils.typeOf)(errors) === 'string') {
        return [errors];
      }

      // assume it's an array of messages
      return errors;
    }
    get hasValidator() {
      return typeof this.args.model?.validate === 'function';
    }

    // Ember Changeset does not validate the initial state. Properties are not
    // validated until they are set the first time. But Ember Bootstrap may show
    // validation results before the property was changed. We need to make sure
    // that changeset is validated at that time.
    // Ember Bootstrap may show the validation in three cases:
    // 1. User triggered one of the events that should cause validation errors to
    //    be shown (e.g. focus out) by interacting with the form element.
    //    Ember Bootstrap stores these state in `showOwnValidation` property of
    //    the form element.
    // 2. User submits the form. Ember Bootstrap will show validation errors
    //    for all form elements in that case. That state is handled by
    //    `showAllValidations` arguments passed to the form element.
    // 3. User passes in a validation error or warning explicilty using
    //    `customError` or `customWarning` arguments of the form element.
    // Ember Bootstrap ensures that the model is valided as part of its submit
    // handler. So we can assume that validations are run in second case. Ember
    // Bootstrap does not show the validation errors of the model but only the
    // custom error and warning if present. So it does not matter if initial
    // state is validated or not. That means we only have to handle the first
    // case.
    // Ember Bootstrap does not provide any API for validation plugins to support
    // these needs. We have to override a private method to run the validate
    // logic for now.
    async showValidationOnHandler(event) {
      let validationShowBefore = this.showOwnValidation;

      // run original implementation provided by Ember Bootstrap
      super.showValidationOnHandler(event);

      // run initial validation if
      //   - visibility of validations changed
      let canValidate = this.hasValidator && this.args.property;
      let validationVisibilityChanged = !validationShowBefore && this.showOwnValidation;
      if (canValidate && validationVisibilityChanged) {
        await this.args.model.validate(this.args.property);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "showValidationOnHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showValidationOnHandler"), _class.prototype)), _class);
});
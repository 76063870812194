define("ember-resource-tasks/services/ember-resource-tasks/-private/do-not-use/arg-cache", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * This service is basically just Map<string, Map<string, any>>
   *  - this cache exists as a service so it can be cleaned up during tests
   *  - used by MemoizedTask where args must be stringifyable
   *    as they are joined together to use as a key to the map.
   *    at some point, we may want a dynamically deep Map
   *    so that the stringifyability doesn't matter, and then
   *    any args could be passed to the MemoizedTask fnArgs
   *
   */
  class ArgCache extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_cache", new Map());
    }
    getEnsuringBucket(cacheName) {
      let bucket = this._cache.get(cacheName);
      if (!bucket) {
        bucket = new Map();
        this._cache.set(cacheName, bucket);
      }
      return bucket;
    }
  }
  _exports.default = ArgCache;
});
define("ember-resource-tasks/index", ["exports", "ember-resource-tasks/-private/task", "ember-resource-tasks/-private/memoized-task", "ember-resource-tasks/-private/utils"], function (_exports, _task, _memoizedTask, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "MemoizedTask", {
    enumerable: true,
    get: function () {
      return _memoizedTask.MemoizedTask;
    }
  });
  Object.defineProperty(_exports, "Task", {
    enumerable: true,
    get: function () {
      return _task.Task;
    }
  });
  Object.defineProperty(_exports, "valueFor", {
    enumerable: true,
    get: function () {
      return _utils.valueFor;
    }
  });
});
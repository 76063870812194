define("ember-light-table/components/columns/base", ["exports", "@ember/component", "@ember/object", "@ember/utils", "ember-light-table/templates/components/columns/base", "ember-light-table/mixins/draggable-column", "ember-light-table/utils/css-styleify"], function (_exports, _component, _object, _utils, _base, _draggableColumn, _cssStyleify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Light Table
   * @submodule Column Types
   */

  /**
   * @module Column Types
   * @class Base Column
   */

  const Column = _component.default.extend(_draggableColumn.default, {
    layout: _base.default,
    tagName: 'th',
    classNames: ['lt-column'],
    attributeBindings: ['style', 'colspan', 'rowspan'],
    classNameBindings: ['align', 'isGroupColumn:lt-group-column', 'isHideable', 'isSortable', 'isSorted', 'isResizable', 'isResizing', 'isDraggable', 'column.classNames'],
    isGroupColumn: _object.computed.readOnly('column.isGroupColumn'),
    isSortable: _object.computed.readOnly('column.sortable'),
    isSorted: _object.computed.readOnly('column.sorted'),
    isHideable: _object.computed.readOnly('column.hideable'),
    isResizable: _object.computed.readOnly('column.resizable'),
    isDraggable: _object.computed.readOnly('column.draggable'),
    isResizing: false,
    style: (0, _object.computed)('column.width', function () {
      return (0, _cssStyleify.default)(this.column.getProperties(['width']));
    }),
    align: (0, _object.computed)('column.align', function () {
      return `align-${this.get('column.align')}`;
    }),
    /**
     * @property label
     * @type {String}
     */
    label: _object.computed.oneWay('column.label'),
    /**
     * @property table
     * @type {Table}
     */
    table: null,
    /**
     * @property column
     * @type {Column}
     */
    column: null,
    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,
    /**
     * @property extra
     * @type {Object}
     */
    extra: null,
    /**
     * @property sortIcons
     * @type {Object}
     */
    sortIcons: null,
    /**
     * @property sortIconProperty
     * @type {String|null}
     * @private
     */
    sortIconProperty: (0, _object.computed)('column.{sortable,sorted,ascending}', function () {
      let sorted = this.get('column.sorted');
      if (sorted) {
        let ascending = this.get('column.ascending');
        return ascending ? 'iconAscending' : 'iconDescending';
      }
      let sortable = this.get('column.sortable');
      return sortable ? 'iconSortable' : null;
    }),
    /**
     * @property colspan
     * @type {Number}
     */
    colspan: (0, _object.computed)('column', 'column.visibleSubColumns.[]', function () {
      let subColumns = this.get('column.visibleSubColumns');
      return !(0, _utils.isEmpty)(subColumns) ? subColumns.length : 1;
    }),
    /**
     * @property rowspan
     * @type {Number}
     */
    rowspan: (0, _object.computed)('_rowspan', 'column.visibleSubColumns.[]', {
      get() {
        if (this._rowspan) {
          return this._rowspan;
        }
        let subColumns = this.get('column.visibleSubColumns');
        return !(0, _utils.isEmpty)(subColumns) ? 1 : 2;
      },
      set(key, value) {
        return this._rowspan = value;
      }
    })
  });
  Column.reopenClass({
    positionalParams: ['column']
  });
  var _default = _exports.default = Column;
});
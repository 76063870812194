define("ember-resource-tasks/-private/utils", ["exports", "@ember/test-waiters", "@ember/object"], function (_exports, _testWaiters, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "consumeTag", {
    enumerable: true,
    get: function () {
      return _object.get;
    }
  });
  _exports.extractTaskData = extractTaskData;
  _exports.toCacheKey = toCacheKey;
  _exports.valueFor = valueFor;
  _exports.waitFor = void 0;
  const waitFor = _exports.waitFor = _testWaiters.waitFor;
  function toCacheKey(...tokens) {
    return tokens.flat().join('-');
  }

  /**
   * No-op TypeScript helper for helping reshape the type of the Resource in TypeScript files
   */
  function valueFor(instance) {
    return instance;
  }

  /**
   * NOTE: some properties on a task are not iterable, therefore not included in the spread
   *   This is probably fine, for the most part.
   *   This was more of an issue for ember-concurrency@v2 support though
   */
  function extractTaskData(task) {
    return {
      ...task,
      value: task.value,
      isRunning: task.isRunning
    };
  }
});
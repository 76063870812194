define("ember-resource-tasks/-private/task", ["exports", "ember-could-get-used-to-this", "@ember/object", "@ember/debug", "ember-concurrency-ts", "ember-concurrency-decorators", "ember-resource-tasks/-private/utils"], function (_exports, _emberCouldGetUsedToThis, _object, _debug, _emberConcurrencyTs, _emberConcurrencyDecorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Task = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   * Whenever any args change *value*, the task will re run
   * All args must be stringish
   */
  let Task = _exports.Task = (_class = class Task extends _emberCouldGetUsedToThis.Resource {
    /**
     * @public
     *
     * This is the return value of a resource
     */
    get value() {
      let task = this._task.last;
      (false && !(task) && (0, _debug.assert)(`A task failed to start`, task)); // entangle with the task's properties when it resolves
      (0, _utils.consumeTag)(task, 'value');
      (0, _utils.consumeTag)(task, 'isFinished');
      (0, _utils.consumeTag)(task, 'error');
      return {
        ...(0, _utils.extractTaskData)(task),
        retry: this._perform
      };
    }
    get _task() {
      return (0, _emberConcurrencyTs.taskFor)(this.__task);
    }
    *__task() {
      let {
        fn,
        args
      } = this.args.named;

      // Because async functions can set tracked data during rendering, (before an await is hit in execution)
      // we are presented with this assertion:
      //
      // Error: Assertion Failed: You attempted to update `someProperty` on `<SomeObject:ember1159>`,
      // but it had already been used previously in the same computation.
      // Attempting to update a value after using it in a computation can cause logical errors,
      // infinite revalidation bugs, and performance issues, and is not supported.
      yield Promise.resolve();
      let result = yield fn(...args);
      return result;
    }
    _perform() {
      this._task.perform();
    }

    /**
     * Resource lifecycle methods
     */
    setup() {
      this._perform();
    }
    update() {
      this._perform();
    }
    teardown() {
      this._task.cancelAll();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "__task", [_emberConcurrencyDecorators.task, _utils.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "__task"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_perform", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_perform"), _class.prototype)), _class);
});